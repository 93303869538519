import React, { useEffect, useRef } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ReactMarkdown from 'react-markdown';
import Hero from '../components/Hero';
import CustomLink from '../components/Link';
import Typeform from '../components/Typeform';
import Protect from 'react-app-protect';

const ReferencePageTemplate = (props) => {
  if (!props) return null;

  const node_locale = props.pageContext.node_locale;
  const translation = props.pageContext.translation;
  const pageContent = props.pageContext.data;
  const footerContent = props.pageContext.footer.node;
  const headerContent = props.pageContext.header.node;

  const localSeo = props.pageContext.data.seo && props.pageContext.data.seo;
  const slug = props.pageContext.data.slug;

  return (
    <>
      {typeof window !== 'undefined' && (
        <Protect sha512="5F8A01670BDECBAFD1D1D5D23E3CB2205B65FE9BABA76542EDB8B36EDD69215E8042015790D3ED2C1A6E3CB949966991C8BECF34EEB02ABA9FF1EDE860EB3E38">
          {!pageContent.typeform ? (
            <Layout
              themeColor="mode--light"
              currentLocation={props.path}
              currentNodeLocale={node_locale}
              footerContent={footerContent}
              headerContent={headerContent}
              translation={translation}
            >
              {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

              {/* Hero Section */}
              {pageContent.hero && (
                <Hero
                  data={pageContent.hero}
                  currentNodeLocale={pageContent.node_locale}
                  qrCodeSection={pageContent.qrCode}
                />
              )}

              {/* Content Image Section */}
              {pageContent.contentImage && (
                <section className="section">
                  <div className="container">
                    {pageContent.contentImage.map((feature, index) => {
                      return (
                        <div key={index} className="content-image">
                          <div className="content-image__content ">
                            {documentToReactComponents(feature.content.json)}
                            {feature.link && (
                              <div className="content__link">
                                <CustomLink link={feature.link} />
                              </div>
                            )}
                          </div>
                          <div className="content-image__image">
                            {feature.image && (
                              <img
                                src={feature.image.file.url}
                                alt={feature.image.description ? feature.image.description : ''}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </section>
              )}

              {/* How to steps */}
              {pageContent.howToUseTheApp && (
                <section className="section">
                  <div className="container">
                    <div className="section__heading">
                      <ReactMarkdown
                        source={pageContent.howToUseTheApp.content.childMarkdownRemark.rawMarkdownBody}
                        disallowedTypes={['paragraph']}
                        unwrapDisallowed={true}
                      />
                    </div>
                    <div className="steps">
                      <ol className="how-to">
                        {pageContent.howToUseTheApp.referenceOne.map((info, index) => {
                          return (
                            <li key={index} className="how-to__step">
                              {documentToReactComponents(info.content.json)}
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                    <div className="flex justify-center">
                      {pageContent.howToUseTheApp.referenceTwo.images.map((image, index) => {
                        return (
                          <div className="step__apps" key={index}>
                            <a href={pageContent.howToUseTheApp.referenceTwo.imageLinks[index]} target="_blank">
                              <img
                                className="w-32 shadow-lg"
                                src={image.file.url}
                                key={index}
                                alt={image.description ? image.description : ''}
                              />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </section>
              )}
            </Layout>
          ) : (
            <Layout
              themeColor="mode--light"
              currentLocation={props.path}
              currentNodeLocale={node_locale}
              footerContent={footerContent}
              headerContent={headerContent}
              translation={translation}
              excludeFooter
            >
              {localSeo && <SEO seoData={localSeo} url={props.location && slug} />}

              {/* Hero Section */}
              {pageContent.hero && (
                <Hero
                  data={pageContent.hero}
                  currentNodeLocale={pageContent.node_locale}
                  qrCodeSection={pageContent.qrCode}
                />
              )}
              {/* Typeform  */}
              <Typeform data={pageContent.typeform} />
            </Layout>
          )}
        </Protect>
      )}
    </>
  );
};

export default ReferencePageTemplate;
